import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSRVID, asyncGetServerList } from '../store/tempData';
import Select from 'react-select'
import './ModalServerListStyle.css'

export const ModalServerList = ({active, setActive}) => {
	const mainData = useSelector(state => state.mainData);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(asyncGetServerList());
	}, []);

	return(
		<div onClick={() => setActive(false)} className="flex justify-center items-center w-full h-full bg-black/[0.4] fixed z-[51] top-0 left-0 backdrop-blur-sm">
			<div className="rounded-md ServerListSize bg-base-100" onClick={e => e.stopPropagation()}>
				<span className="flex items-center justify-center pt-8 font-bold">Сервера</span>
				<Select className="items-center justify-center m-8 text-black bg-base-200" options={mainData.servers} defaultValue={mainData.servers.find(x => Number(x.value) === Number(mainData.srv_id)) === undefined ? "" : mainData.servers.find(x => Number(x.value) === Number(mainData.srv_id))} onChange={(event) => dispatch(setSRVID(Number(event.value))) } />
			</div>
		</div>
	);
}

// [mainData.periodLeftStandart, mainData.periodRightStandart]