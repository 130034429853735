import React from 'react';
import { useSelector } from 'react-redux';
import NavigationBar from './components/NavigationBar';
import MainAdminData from './components/MainAdminData';
import AdminProfile from './components/AdminProfile';

function App() {
	const mainData = useSelector(state => state.mainData);

	return (
		<div id="main__container">
			<NavigationBar />
			{ mainData.profileActive === false ? <MainAdminData /> : <AdminProfile /> }
		</div>
	);
}

export default App;
