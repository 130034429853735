import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { authUser } from '../store/authReducer';

export const ModalSideBarMenu = (props) => {
	const {
		active,
		setActive,
		activeCalendar,
		activateCalendar,
		activeServer,
		activateServer
	} = props;

	const mainData = useSelector(state => state.mainData);
	const auth = useSelector(state => state.auth);
	const dispatch = useDispatch();

	function ModalToggle(elementID) {
		let elem = document.getElementById(elementID);
		elem.classList.toggle("modal-open");
	}

	let calendarBadge, serverBadge;

	calendarBadge = <li className="my-2 badge badge-primary badge-lg cursor-default justify-self-end sm:hidden w-[300px] text-center">
						Период: {mainData.periodLeft.replace(/-/g, ".")} — {mainData.periodRight.replace(/-/g, ".")}
					</li>

	serverBadge = 	<li className="my-2 badge badge-primary badge-lg cursor-default justify-self-end sm:hidden w-[200px] text-center">
						Сервер: {mainData.servers.find(x => Number(x.value) === Number(mainData.srv_id)) === undefined ? mainData.srv_id : mainData.servers.find(x => Number(x.value) === Number(mainData.srv_id)).label}
					</li>

	return (
		<div onClick={() => setActive(false)} className="flex justify-center items-center w-full h-full fixed z-50 top-0 left-0">
			<div className="bg-base-200 w-[350px] h-full z-10 fixed right-0 top-0 items-start" onClick={e => e.stopPropagation()}>
				<div className="flex mx-auto justify-start items-start mt-4 ml-4">
					<button className="badge badge-error badge-lg h-[38px] w-[38px]" onClick={() => setActive(false)}></button>
				</div>
				<ul className="p-4 grid grid-cols-1 row-auto py-4">
					<li className="my-2 mt-6 btn btn-outline btn-primary justify-self-end w-[200px] text-center" onClick={() => activateServer(!activeServer)}>Выбрать сервер</li>
					<li className="my-2 btn btn-outline btn-primary justify-self-end w-[200px] text-center" onClick={() => activateCalendar(!activeCalendar)}>Выбрать период</li>
					<li className="my-2 btn btn-outline btn-primary justify-self-end w-[200px] text-center" onClick={() => { if(auth.auth === false) ModalToggle("auth-modal-div"); else dispatch(authUser(false)) } }>{auth.auth === false ? 'Авторизация' : 'Выйти'}</li>
					{serverBadge}
					{calendarBadge}
				</ul>
			</div>
		</div>
	);
};
