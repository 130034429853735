import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncGetAdminsSRVData } from '../store/adminsData';
import { setProfileActive } from '../store/tempData';
import { motion } from 'framer-motion';

const MainAdminData = () => {
	const dispatch = useDispatch();
	const adminsData = useSelector(state => state.admins);
	const mainData = useSelector(state => state.mainData);
	const [activeAdmin, setActiveAdmin] = useState("");
	const [adminInfo, setAdminInfo] = useState(Object.keys(adminsData).find(x => adminsData[x].steamid === activeAdmin) !== undefined ? adminsData[Object.keys(adminsData).find(x => adminsData[x].steamid === activeAdmin)] : null);

	// function seconds2time (seconds) {
	// 	var hours   = Math.floor(seconds / 3600);
	// 	var minutes = Math.floor((seconds - (hours * 3600)) / 60);
	// 	var seconds = seconds - (hours * 3600) - (minutes * 60);
	// 	var time = "";
	
	// 	if (hours != 0) {
	// 	  time = hours+":";
	// 	}
	// 	if (minutes != 0 || time !== "") {
	// 	  minutes = (minutes < 10 && time !== "") ? "0"+minutes : String(minutes);
	// 	  time += minutes+":";
	// 	}
	// 	if (time === "") {
	// 	  time = seconds+"s";
	// 	}
	// 	else {
	// 	  time += (seconds < 10) ? "0"+seconds : String(seconds);
	// 	}
	// 	return time;
	// }

	function seconds2time(seconds) {
		var days = Math.floor(seconds / (3600*24));
		seconds  -= days*3600*24;
		var hrs   = Math.floor(seconds / 3600);
		seconds  -= hrs*3600;
		var mnts = Math.floor(seconds / 60);
		seconds  -= mnts*60;
		return (days + " д. " + hrs + " ч. " + mnts + " м. " + seconds + " с.");
	}

	const UpdateAdminList = () => {
		dispatch(asyncGetAdminsSRVData(mainData.srv_id, mainData.periodLeft, mainData.periodRight));
	}

	useEffect(() => {
		UpdateAdminList();
	}, [mainData.periodLeft, mainData.periodRight, mainData.srv_id])

	useEffect(() => {
		setAdminInfo(Object.keys(adminsData).find(x => adminsData[x].steamid === activeAdmin) !== undefined ? adminsData[Object.keys(adminsData).find(x => adminsData[x].steamid === activeAdmin)] : null);
	}, [activeAdmin]);

	return(
		// <div className="grid grid-cols-1 grid-flow-row auto-rows-max pt-8 pb-0 text-center border-4 border-indigo-700 font-sans antialiased tracking-wide text-cyan-900">
		// 	<div className="grid grid-cols-6 content-center text-center pb-8 border-b-2 border-indigo-600 font-bold text-lg h-20">
		// 		<div className="grid content-center border-r-4 border-indigo-600">Имя администратора</div>
		// 		<div className="grid content-center border-r-4 border-indigo-600">Сервер</div>
		// 		<div className="grid content-center border-r-4 border-indigo-600">Общий онлайн</div>
		// 		<div className="grid content-center border-r-4 border-indigo-600">Последний вход</div>
		// 		<div className="grid content-center border-r-4 border-indigo-600">Доступные награды</div>
		// 		<button onClick={UpdateAdminList} className="grid content-center h-14 border-none bg-indigo-500 w-32 justify-self-center rounded-full text-white">Обновить</button>
		// 	</div>

		// 	{
		// 		Object.keys(adminsData).map((data, index) =>
		// 			<div className="grid pt-6 pb-4 grid-cols-6 text-center content-center border-t-4 border-indigo-600" key={index}>
		// 				<div className="grid border-r-4 border-indigo-600 content-center">{adminsData[data].name}</div>
		// 				<div className="grid border-r-4 border-indigo-600 content-center">{adminsData[data].server}</div>
		// 				<div className="grid border-r-4 border-indigo-600 content-center">{adminsData[data].online}</div>
		// 				<div className="grid border-r-4 border-indigo-600 content-center">{adminsData[data].lastvisit}</div>
		// 				<div className="grid border-r-4 border-indigo-600 content-center">{adminsData[data].reward}</div>
		// 				<button onClick={() => dispatch(setProfileActive({activeProfile: true, admin: adminsData[data].steamid}))} className="grid border-none bg-indigo-500 h-14 w-32 content-center justify-self-center rounded-full text-white">
		// 					Профиль
		// 				</button>
		// 			</div>
		// 		)
		// 	}
		// </div>

		<div className="h-max m-8 bg-base-100 rounded-lg grid grid-cols-9 row-auto">
			<div className="overflow-x-auto w-full col-span-9 xl:col-span-6">
				<table className="table w-full">
					{/* <!-- head --> */}
					<thead>
						<tr>
							<th>Имя</th>
							<th>SteamID</th>
							<th>Онлайн</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{/* <!-- row 1 --> */}
						{Object.keys(adminsData).map((data, index) =>
						<tr key={index}>

							<td onClick={() => setActiveAdmin(adminsData[data].steamid)}>
								<div className="flex items-center space-x-3">
									{/* <div className="avatar">
										<div className="w-12 h-12 mask mask-squircle">
											<img src="https://yt3.ggpht.com/a/AATXAJzwn_VxfpOcpa8n6_WopHslGMiN9pxxhIz-yrHH=s900-c-k-c0xffffffff-no-rj-mo" alt="Avatar Tailwind CSS Component"/>
										</div>
									</div> */}
									<div>
										<motion.div
											className="font-bold hover:cursor-pointer"
											whileHover={{
												scale: 1.524
											}}
								
											transition={{
												duration: 0.2
											}}
										>{adminsData[data].name}</motion.div>
										{/* <div className="text-sm opacity-50">Admin Group</div> */}
									</div>
								</div>
							</td>

							<td>
								{adminsData[data].steamid}
							</td>

							<td>{seconds2time(adminsData[data].online)}</td>

							<th>
								<button onClick={() => dispatch(setProfileActive({activeProfile: true, admin: adminsData[data].steamid}))} className="btn btn-ghost btn-md">Профиль</button>
							</th>
						</tr>
						)}
					</tbody>

					<tfoot>
						<tr>
							<th>Имя</th>
							<th>SteamID</th>
							<th>Онлайн</th>
							<th></th>
						</tr>
					</tfoot>
				</table>
			</div>

			<div className="divider divider-horizontal col-span-1 hidden xl:flex"></div>

			{adminInfo !== null &&
			<div
				className="col-start-8 col-end-9 sticky artboard bg-base-200 rounded-md hidden xl:block drop-shadow-lg w-[300px] h-[568px]"
			>
				<div className="grid grid-cols-1 h-max shadow-none w-[300px]">
					<div className="avatar flex items-center justify-center mt-12">
						<div className="w-36 rounded">
							<img src="https://cdn.onlinewebfonts.com/svg/img_568657.png" />
						</div>
					</div>

					<div className="m-12 mt-8 mb-0 w-[70%] h-[50px] outline outline-1 cursor-default outline-primary text-primary text-center flex items-center justify-center font-mono rounded-sm relative">
						<div className="badge badge-secondary badge-lg absolute top-[-15px] left-[-13px] text-white">Имя</div>
						{adminInfo.name}
					</div>

					<div className="m-12 mt-8 mb-0 w-[70%] h-[50px] outline outline-1 cursor-default outline-primary text-primary text-center flex items-center justify-center font-mono rounded-sm relative">
						<div className="badge badge-secondary badge-lg absolute top-[-15px] left-[-13px] text-white">Последний вход</div>
						{new Date(adminInfo.lastvisit*1000).toString().slice(0, 10).replace(/-/g, ".")}
					</div>

					<div className="m-12 mt-8 mb-0 w-[70%] h-[50px] outline outline-1 cursor-default outline-primary text-primary text-center flex items-center justify-center font-mono rounded-sm relative">
						<div className="badge badge-secondary badge-lg absolute top-[-15px] left-[-13px] text-white">Награды</div>
						{adminInfo.reward}
					</div>

					<div className="m-12 mt-8 w-[70%] h-[50px] cursor-default text-center flex items-center justify-center rounded-sm relative">
						<button onClick={() => dispatch(setProfileActive({activeProfile: true, admin: adminInfo.steamid}))} className="btn btn-primary btn-outline btn-md w-full rounded-md">Профиль</button>
					</div>
				</div>
			</div>
			}
		</div>
	);
};

export default MainAdminData;
