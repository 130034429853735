const defaultState = {
	srv_id: 1,
	periodLeft: new Date().toISOString().slice(0, 10),
	periodLeftStandart: new Date(),
	periodRight: new Date().toISOString().slice(0, 10),
	periodRightStandart: new Date(),
	servers: [],
	profileActive: false,
	selectedAdmin: ""
}

const SET_SRV_ID = "SET_SRV_ID";
const SET_LEFT_PERIOD = "SET_LEFT_PERIOD";
const SET_RIGHT_PERIOD = "SET_RIGHT_PERIOD";
const GET_SERVER_LIST = "GET_SERVER_LIST";
const SET_PROFILE_ACTIVE = "SET_PROFILE_ACTIVE";

export const tempData = (state = defaultState, action) => {
	switch(action.type) {
		case SET_SRV_ID: {
			return {...state, srv_id: Number(action.payload)}
		}

		case SET_LEFT_PERIOD: {
			let date = new Date(action.payload);
			return {...state, periodLeft: new Date(date.setDate(date.getDate() + 1)).toISOString().slice(0, 10), periodLeftStandart: new Date(action.payload)}
		}

		case SET_RIGHT_PERIOD: {
			return {...state, periodRight: action.payload.slice(0, 10), periodRightStandart: new Date(action.payload)}
		}

		case GET_SERVER_LIST: {
			return {...state, servers: action.payload}
		}

		case SET_PROFILE_ACTIVE: {
			return {...state, profileActive: action.payload.activeProfile, selectedAdmin: action.payload.admin}
		}

		default:
			return state;
	}
}

export const asyncGetServerList = () => 
	function (dispatch) {
		let query = "api/server-side/api/source/GetServerList.php";
		fetch(query)
			.then(response => response.json())
			.then(result => {
				if(result.hasOwnProperty("error") === false)
					dispatch(getServerList(result));
			});
	}

export const setSRVID = (payload) => ({type: SET_SRV_ID, payload})
export const setLeftPeriod = (payload) => ({type: SET_LEFT_PERIOD, payload})
export const setRightPeriod = (payload) => ({type: SET_RIGHT_PERIOD, payload})
export const getServerList = (payload) => ({type: GET_SERVER_LIST, payload})
export const setProfileActive = (payload) => ({type: SET_PROFILE_ACTIVE, payload})