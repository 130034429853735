import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
import { adminsData } from './adminsData';
import { authReducder } from './authReducer';
import { tempData } from './tempData';

const rootReducer = combineReducers({
		auth: authReducder,
		admins: adminsData,
		mainData: tempData
	}
);

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));