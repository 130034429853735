import React from 'react';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './ModalCalendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { setLeftPeriod, setRightPeriod } from '../store/tempData';

export const ModalCalendar = ({active, setActive}) => {
	const mainData = useSelector(state => state.mainData);
	const dispatch = useDispatch();

	const setNewPeriod = (dates) => {
		dispatch(setLeftPeriod(dates[0].toISOString()))
		dispatch(setRightPeriod(dates[1].toISOString()))
	}

	return(
		<div onClick={() => setActive(false)} className="flex justify-center items-center w-full h-full bg-black/[0.4] fixed z-[51] top-0 left-0 backdrop-blur-sm">
			<div onClick={e => e.stopPropagation()}>
				<Calendar className="drop-shadow-xl bg-base-100" onChange={(dates) => {setNewPeriod(dates)}} defaultValue={[mainData.periodLeftStandart, mainData.periodRightStandart]} returnValue={"range"} selectRange={true} />
			</div>
		</div>
	);
}

// [mainData.periodLeftStandart, mainData.periodRightStandart]