const defaultState = {}

const GET_ADMINS_SRV_DATA = "GET_ADMINS_SRV_DATA";
const GET_ADMINS_ALL_DATA = "GET_ADMINS_ALL_DATA";

export const adminsData = (state = defaultState, action) => {
	switch(action.type) {
		case GET_ADMINS_SRV_DATA: {
			// запрос к серверу на получение данных по определённому srv id
			return {...action.payload}
		}
		case GET_ADMINS_ALL_DATA: {
			// #TODO: запрос к серверу на получение данных по всем srv id
			return {...state}
		}
		default:
			return state;
	}
}

export const asyncGetAdminsSRVData = (srv_id, periodLeft, periodRight) => 
	function (dispatch) {
		let query = "api/server-side/api/source/GetAdminsData.php?srv_id=" + srv_id.toString() + "&periodLeft=\"" + periodLeft + "\"&periodRight=\"" + periodRight + "\"";
		fetch(query)
			.then(response => response.json())
			.then(result => {
				if(result.hasOwnProperty("error") === false)
					dispatch(getAdminsSRVData(result))
				else
					dispatch(getAdminsSRVData({}))
			});
	}

export const asyncUpdateRewards = (steamid, rewards, srv_id, periodLeft, periodRight) => 
	function (dispatch) {
		let query = "api/server-side/api/source/UpdateAdminRewards.php";
		const data = {
			"steamid": steamid,
			"rewards": rewards,
			"srv_id": srv_id
		}

		fetch(query, {
			method: "POST",
			headers: {
    			'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(response => {
				if(response.ok)
				{
					dispatch(asyncGetAdminsSRVData(srv_id, periodLeft, periodRight));
				}
			});
			// .then(result => {
			// 	if(result.hasOwnProperty("error") === false)
			// 		dispatch(asyncGetAdminsSRVData(srv_id, periodLeft, periodRight))
			// });
	}

export const asyncUpdateDateTime = (steamid, newTime, srv_id, date, periodLeft, periodRight) => 
	function (dispatch) {
		let query = "api/server-side/api/source/UpdateDateTime.php";
		const data = {
			"steamid": steamid,
			"newTime": newTime,
			"srv_id": srv_id,
			"date": date
		}

		fetch(query, {
			method: "POST",
			headers: {
    			'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(response => {
				if(response.ok)
				{
					dispatch(asyncGetAdminsSRVData(srv_id, periodLeft, periodRight))
				}
			});
	}

export const getAdminsSRVData = (payload) => ({type: GET_ADMINS_SRV_DATA, payload})
export const getAdminsAllData = (payload) => ({type: GET_ADMINS_ALL_DATA, payload})