import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { asyncGetAdminsSRVData, asyncUpdateDateTime, asyncUpdateRewards } from '../store/adminsData';
import { setProfileActive } from '../store/tempData';

const AdminProfile = () => {
	// const defaultOnlineInfoState = [
	// 	{
	// 		time: 0,
	// 		date: "0000-00-00"
	// 	}
	// ];

	// function seconds2time (seconds) {
	// 	var hours   = Math.floor(seconds / 3600);
	// 	var minutes = Math.floor((seconds - (hours * 3600)) / 60);
	// 	var seconds = seconds - (hours * 3600) - (minutes * 60);
	// 	var time = "";
	
	// 	if (hours != 0) {
	// 	  time = hours+":";
	// 	}
	// 	if (minutes != 0 || time !== "") {
	// 	  minutes = (minutes < 10 && time !== "") ? "0"+minutes : String(minutes);
	// 	  time += minutes+":";
	// 	}
	// 	if (time === "") {
	// 	  time = seconds+"s";
	// 	}
	// 	else {
	// 	  time += (seconds < 10) ? "0"+seconds : String(seconds);
	// 	}
	// 	return time;
	// }

	function seconds2time(seconds) {
		var hrs   = Math.floor(seconds / 3600);
		seconds  -= hrs*3600;
		var mnts = Math.floor(seconds / 60);
		seconds  -= mnts*60;
		return (hrs + " ч. " + mnts + " м. " + seconds + " с.");
	}

	const dispatch = useDispatch();
	const mainData = useSelector(state => state.mainData);
	const adminsData = useSelector(state => state.admins);
	const auth = useSelector(state => state.auth);
	const [adminOnlineInfo, setAdminOnlineInfo] = useState(null);
	const [adminInfo, setAdminInfo] = useState(Object.keys(adminsData).find(x => adminsData[x].steamid === mainData.selectedAdmin) !== undefined ? adminsData[Object.keys(adminsData).find(x => adminsData[x].steamid === mainData.selectedAdmin)] : { name: "none", lastvisit: 0, steamid: "none", server: "none", online: 0, reward: 0 });
	const [rewards, setRewards] = useState(0);
	const [selectedTime, setSelectedTime] = useState({"time": 0, "date": "0"});
	const [newTime, setNewTime] = useState(0);

	// let adminInfo = {
	// 	name: "none",
	// 	steamid: "none",
	// 	online: 0,
	// 	reward: 0,
	// 	lastvisit: 0,
	// 	server: "none"
	// };

	// let adminInfo = Object.keys(adminsData).find(x => adminsData[x].steamid === mainData.selectedAdmin) !== undefined ? adminsData[Object.keys(adminsData).find(x => adminsData[x].steamid === mainData.selectedAdmin)] : { name: "none", steamid: "none", online: 0, reward: 0, lastvisit: 0, server: "none" };

	// let adminOnlineInfo = [
	// 	{
	// 		time: 0,
	// 		date: "0000-00-00"
	// 	}
	// ];

	const getAdminInfo = () => {
		const query = "api/server-side/api/source/GetAdminInfo.php?periodLeft=\"" + mainData.periodLeft + "\"&periodRight=\"" + mainData.periodRight + "\"&sid=\"" + mainData.selectedAdmin + "\"&srv_id=" + mainData.srv_id.toString();
		fetch(query)
			.then(response => response.json())
			.then(result => {
				setAdminOnlineInfo(result);
			});
	}

	useEffect(() => {
		setAdminInfo(Object.keys(adminsData).find(x => adminsData[x].steamid === mainData.selectedAdmin) !== undefined ? adminsData[Object.keys(adminsData).find(x => adminsData[x].steamid === mainData.selectedAdmin)] : { ...adminInfo, online: 0 });
		setRewards(adminInfo.reward);
	}, [adminsData]);

	useEffect(() => {
		dispatch(asyncGetAdminsSRVData(mainData.srv_id, mainData.periodLeft, mainData.periodRight));
		getAdminInfo();
	}, [mainData.srv_id, mainData.periodRight, newTime]);

	function ModalToggle(elementID) {
		let elem = document.getElementById(elementID);
		elem.classList.toggle("modal-open");
	}
	

  	return (
		// <div className="grid grid-cols-9 auto-cols-max grid-flow-row auto-rows-max w-full h-full pt-4">
		// 	<div className="grid col-start-1 col-end-3 justify-self-center ml-2 bg-white rounded-md drop-shadow-lg text-black">
		// 		<div className="m-4">
		// 			<div className="grid justify-items-center justify-center items-center pb-4 font-semibold font-mono antialiased tracking-wide text-lg">{adminInfo.name}</div>
		// 			<img className="grid justify-items-center justify-center items-center rounded-md w-72" src={art} alt="test" />
		// 		</div>
		// 		<div className="pl-8 font-semibold font-mono antialiased tracking-wide underline underline-offset-2 text-md">
		// 			<div className="grid h-8 items-center pt-4">STEAMID: {adminInfo.steamid}</div>
		// 			<div className="grid h-8 items-center pt-4">Онлайн за период: {adminInfo.online}</div>
		// 			<div className="grid h-8 items-center pt-4">Награды: {adminInfo.reward}</div>
		// 			<div className="grid h-8 items-center pt-4">Последний вход: {new Date(adminInfo.lastvisit*1000).toISOString().slice(0, 10)}</div>
		// 			<div className="grid h-8 items-center pt-4">Сервер: {adminInfo.server}</div>
		// 		</div>
		// 		<div className="grid items-center p-8 text-sky-50">
		// 			<button className="h-14 rounded-md bg-slate-700 drop-shadow-xl" onClick={() => dispatch(setProfileActive({activeProfile: false, admin: ""}))}>
		// 				Главная страница
		// 			</button>
		// 		</div>
		// 	</div>

		// 	<div className="grid grid-cols-[repeat(auto-fill,_minmax(min(100%,_89px),_1fr))] col-start-3 col-end-10 auto-rows-[minmax(80px,_auto)] h-[min-content] gap-4 pb-4 pr-4 pl-4 font-mono antialiased tracking-wide text-md underline underline-offset-2 drop-shadow-lg">
		// 		{adminOnlineInfo !== null ? Object.keys(adminOnlineInfo).map((data, index) => 
		// 			<div className="grid col-span-2 p-2 pl-6 bg-white rounded-md" key={data}>
		// 				<span>
		// 					Дата: {adminOnlineInfo[data].date}
		// 				</span>
		// 				<span>
		// 					Онлайн: {new Date(adminOnlineInfo[data].time*1000).getHours() + new Date(adminOnlineInfo[data].time*1000).getTimezoneOffset()/60}ч.
		// 				</span>
		// 			</div>
		// 		) : <div className="grid col-span-6 h-20 p-2 pl-6 white rounded-md justify-center items-center content-center text-center text-black">
		// 				<span>
		// 					Данные на выбранном сервере/периоде не найдены
		// 				</span>
		// 			</div>
		// 		}
		// 	</div>
		// </div>

		<div className="h-max m-8 bg-base-100 rounded-lg grid grid-cols-9 row-auto">
			<div className="overflow-x-auto w-full col-span-9 xl:col-span-6">
				<table className="table w-full">
					<thead>
						<tr>
							<th>Дата</th>
							<th>Онлайн</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{adminOnlineInfo !== null && Object.keys(adminOnlineInfo).map((data, index) => (
							<tr key={index}>
								<td>
									{adminOnlineInfo[data].date.replace(/-/g, ".")}
								</td>

								<td>{seconds2time(adminOnlineInfo[data].time)}</td>

								{auth.auth === true &&
								<th>
									<button onClick={() => { setSelectedTime({"time": Math.round(adminOnlineInfo[data].time/60/60), "date": adminOnlineInfo[data].date}); ModalToggle("time-stat-modal-div"); }} className="btn btn-ghost btn-md">Редактировать</button>
								</th>
								}
							</tr>
						))}
					</tbody>

					<tfoot>
						<tr>
							<th>Дата</th>
							<th>Онлайн</th>
							<th></th>
						</tr>
					</tfoot>
				</table>
			</div>

			<div className="divider divider-horizontal col-span-1 hidden xl:flex"></div>

			<div
				className="col-start-8 col-end-9 sticky artboard bg-base-200 rounded-md hidden xl:block drop-shadow-lg w-[300px] h-[auto]"
			>
				<div className="grid grid-cols-1 h-max shadow-none w-[300px]">
					<div className="avatar flex items-center justify-center mt-12">
						{(auth.auth === false &&
						<div className="w-36 rounded">
							<img src="https://cdn.onlinewebfonts.com/svg/img_568657.png" />
						</div>) || (auth.auth === true &&

						<div onClick={() => ModalToggle("stat-modal-div")} className="w-36 rounded">
							<img src="https://cdn.onlinewebfonts.com/svg/img_568657.png" />
						</div>)}
					</div>

					<div className="m-12 mt-8 mb-0 w-[70%] h-[50px] outline outline-1 cursor-default outline-primary text-primary text-center flex items-center justify-center font-mono rounded-sm relative">
						<div className="badge badge-secondary badge-lg absolute top-[-15px] left-[-13px] text-white">Имя</div>
						{adminInfo.name}
					</div>

					<div className="m-12 mt-8 mb-0 w-[70%] h-[50px] outline outline-1 cursor-default outline-primary text-primary text-center flex items-center justify-center font-mono rounded-sm relative">
						<div className="badge badge-secondary badge-lg absolute top-[-15px] left-[-13px] text-white">STEAMID</div>
						{adminInfo.steamid}
					</div>

					<div className="m-12 mt-8 mb-0 w-[70%] h-[50px] outline outline-1 cursor-default outline-primary text-primary text-center flex items-center justify-center font-mono rounded-sm relative">
						<div className="badge badge-secondary badge-lg absolute top-[-15px] left-[-13px] text-white">Онлайн</div>
						{seconds2time(adminInfo.online)}
					</div>

					<div className="m-12 mt-8 mb-0 w-[70%] h-[50px] outline outline-1 cursor-default outline-primary text-primary text-center flex items-center justify-center font-mono rounded-sm relative">
						<div className="badge badge-secondary badge-lg absolute top-[-15px] left-[-13px] text-white">Награды</div>
						{adminInfo.reward}
					</div>

					<div className="m-12 mt-8 mb-0 w-[70%] h-[50px] outline outline-1 cursor-default outline-primary text-primary text-center flex items-center justify-center font-mono rounded-sm relative">
						<div className="badge badge-secondary badge-lg absolute top-[-15px] left-[-13px] text-white">Последний вход</div>
						{new Date(adminInfo.lastvisit*1000).toISOString().slice(0, 10).replace(/-/g, ".")}
					</div>

					<div className="m-12 mt-8 w-[70%] h-[50px] cursor-default text-center flex items-center justify-center rounded-sm relative">
						<button onClick={() => dispatch(setProfileActive({activeProfile: false, admin: ""}))} className="btn btn-primary btn-outline btn-md w-full rounded-md">Вернуться назад</button>
					</div>
				</div>
			</div>

			{/* NOTE: Modal Stat Window */}
			<div id="stat-modal-div" className="modal backdrop-blur-sm" onClick={() => ModalToggle("stat-modal-div")}>
				<div className="modal-box" onClick={e => e.stopPropagation()}>
					<div className="py-2 flex flex-col justify-center items-center">
						<p className="py-4 flex justify-center">Награды</p>
						<input type="text" placeholder="Количество наград" onChange={(e) => setRewards(e.target.value)} value={rewards} className="input input-bordered input-primary w-full max-w-xs" />
					</div>

					<div className="modal-action justify-center">
						<label onClick={() => { dispatch(asyncUpdateRewards(adminInfo.steamid, rewards, mainData.srv_id, mainData.periodLeft, mainData.periodRight)); ModalToggle("stat-modal-div"); }} className="btn">Применить</label>
					</div>
				</div>
			</div>

			{/* NOTE: Modal Time Stat Window */}
			<div id="time-stat-modal-div" className="modal backdrop-blur-sm" onClick={() => ModalToggle("time-stat-modal-div")}>
				<div className="modal-box" onClick={e => e.stopPropagation()}>
					<div className="py-2 flex flex-col justify-center items-center">
						<p className="py-4 flex justify-center">Дата</p>
						<input type="text" readOnly placeholder="Дата" value={selectedTime.date} className="input input-bordered input-primary w-full max-w-xs" />
					</div>

					<div className="py-2 flex flex-col justify-center items-center">
						<p className="py-4 flex justify-center">Время</p>
						<input type="text" placeholder="Время в часах" onChange={e => { setSelectedTime({...selectedTime, time: e.target.value}); setNewTime(e.target.value); }} value={selectedTime.time} className="input input-bordered input-primary w-full max-w-xs" />
					</div>

					<div className="modal-action justify-center">
						<label onClick={() => { dispatch(asyncUpdateDateTime(adminInfo.steamid, newTime*60*60, mainData.srv_id, selectedTime.date, mainData.periodLeft, mainData.periodRight)); getAdminInfo(); setNewTime(0); ModalToggle("time-stat-modal-div"); }} className="btn">Применить</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminProfile;
