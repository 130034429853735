import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalCalendar } from './ModalCalendar';
import { ModalServerList } from './ModalServerList';
import { asyncGetServerList } from '../store/tempData';
import { ModalSideBarMenu } from './ModalSideBarMenu';
import { authUser, asyncAuthUser } from '../store/authReducer';

const NavigationBar = () => {
	const mainData = useSelector(state => state.mainData);
	const auth = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const [activeCalendar, setActiveCalendar] = useState(false);
	const [activeServerModal, setActiveServerModal] = useState(false);
	const [activeModalSideBarMenu, setActiveModalSideBarMenu] = useState(false);
	const [name, setName] = useState("");
	const [pass, setPass] = useState("");

	useEffect(() => {
		dispatch(asyncGetServerList());
	}, []);

	useEffect(() => {
		setName("");
		setPass("");
	}, [auth.auth]);

	function ModalToggle(elementID) {
		let elem = document.getElementById(elementID);
		elem.classList.toggle("modal-open");
	}

	let calendarBadge, serverBadge;

	if(activeCalendar === true)
	{
		calendarBadge = <div className="badge badge-primary badge-lg ml-6 cursor-default z-[52]">
							Период: {mainData.periodLeft.replace(/-/g, ".")} — {mainData.periodRight.replace(/-/g, ".")}
						</div>
	} else {
		calendarBadge = <div className="badge badge-primary badge-lg ml-6 cursor-default">
							Период: {mainData.periodLeft.replace(/-/g, ".")} — {mainData.periodRight.replace(/-/g, ".")}
						</div>
	}

	if(activeServerModal === true)
	{
		serverBadge = 	<div className="badge badge-primary badge-lg cursor-default z-[52]">
							Сервер: {mainData.servers.find(x => Number(x.value) === Number(mainData.srv_id)) === undefined ? mainData.srv_id : mainData.servers.find(x => Number(x.value) === Number(mainData.srv_id)).label}
						</div>
	} else {
		serverBadge = 	<div className="badge badge-primary badge-lg cursor-default">
							Сервер: {mainData.servers.find(x => Number(x.value) === Number(mainData.srv_id)) === undefined ? mainData.srv_id : mainData.servers.find(x => Number(x.value) === Number(mainData.srv_id)).label}
						</div>
	}
	
	
	return(
		// <div>
		// 	<div className="grid grid-cols-10 p-4 bg-sky-100 border-4 border-indigo-700 text-center content-center font-mono antialiased tracking-wide text-white text-lg">
		// 		<button onClick={() => { setActiveServerModal(true) }} className="col-span-1 rounded-full border-none p-6 bg-indigo-500">
		// 			Выбрать сервер
		// 		</button>

		// 		<div className="col-start-2 col-end-4 col-span-1 grid content-center text-black">Продукция Tomiks | AdminReward</div>
		// 		<div className="col-start-4 col-end-4 col-span-1 grid content-center w-20 ">
		// 			<img src={png} alt="SomeShit" className='rounded-full'/>
		// 		</div>

		// 		<div className="col-start-5 col-end-7 col-span-1 grid content-center text-black">
		// 			Период: {mainData.periodLeft} - {mainData.periodRight}
		// 		</div>

		// 		<button onClick={() => setActive(true)} className="col-start-7 col-end-7 col-span-1 rounded-full border-none p-6 bg-indigo-500">
		// 			Выбрать период
		// 		</button>

		// 		<button className="col-start-9 col-end-11 col-span-3 rounded-full border-none bg-indigo-500">
		// 			Авторизоваться
		// 		</button>
		// 	</div>
		// 	{active === true ? <ModalCalendar active={active} setActive={setActive} /> : ''}
		// 	{activeServerModal === true ? <ModalServerList active={activeServerModal} setActive={setActiveServerModal} /> : ''}
		// </div>

		<div className="navbar mb-2 shadow-lg bg-neutral text-neutral-content">
			<div className="flex-none px-2 mx-2">
				<span className="text-lg font-bold">
					AdminReward
				</span>
			</div>

			<div className="flex-1 px-2 mx-2 hidden xl:flex">
				<div className="items-stretch">
					<button className="btn btn-ghost btn-sm rounded-btn" onClick={() => { setActiveServerModal(true) }} >
						Выбрать сервер
					</button>

					<button className="btn btn-ghost btn-sm rounded-btn" onClick={() => setActiveCalendar(true)} >
						Выбрать период
					</button>
				</div>
			</div>

			<div className="flex-1 px-2 mx-2">
				<div className="hidden sm:flex">
					{serverBadge}
					{calendarBadge}
				</div>
			</div>

			<div className="flex-none">
				<div className="hidden xl:flex pr-12">
					{(auth.auth === false && <label onClick={() => ModalToggle("auth-modal-div")} className="btn btn-ghost btn-sm rounded-btn modal-btn">
						Авторизоваться
					</label>) || (auth.auth === true && <button onClick={() => dispatch(authUser(false))} className="btn btn-ghost btn-sm rounded-btn">
						Выйти
					</button>)}
				</div>
			</div>

			<div className="flex-none">
				<button className="btn btn-square btn-ghost xl:hidden" onClick={() => setActiveModalSideBarMenu(true)}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">           
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>               
					</svg>
				</button>
			</div> 

			{activeCalendar === true ? <ModalCalendar active={activeCalendar} setActive={setActiveCalendar} /> : ''}
		 	{activeServerModal === true ? <ModalServerList active={activeServerModal} setActive={setActiveServerModal} /> : ''}
		 	{activeModalSideBarMenu === true ? <ModalSideBarMenu active={activeModalSideBarMenu} setActive={setActiveModalSideBarMenu} activeCalendar={activeCalendar} activateCalendar={setActiveCalendar}
			 activeServer = {activeServerModal} activateServer={setActiveServerModal}
			/> : ''}

			{/* NOTE: Modal Auth Window */}
			<div id="auth-modal-div" className="modal backdrop-blur-sm" onClick={() => ModalToggle("auth-modal-div")}>
				<div className="modal-box" onClick={e => e.stopPropagation()}>
					<div className="py-2 flex flex-col justify-center items-center">
						<input type="text" placeholder="Логин" onChange={(e) => setName(e.target.value)} value={name} className="input input-bordered input-primary w-full max-w-xs" />
					</div>

					<div className="py-2 flex flex-col justify-center items-center">
						<input type="password" placeholder="Пароль" onChange={(e) => setPass(e.target.value)} value={pass} className="input input-bordered input-primary w-full max-w-xs" />
					</div>

					<div className="modal-action justify-center">
						<label onClick={() => { dispatch(asyncAuthUser(name, pass)); ModalToggle("auth-modal-div"); }} className="btn">Авторизоваться</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavigationBar;